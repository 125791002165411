import { mapGetters } from 'vuex'

export default {
  props: {
    focusOnMount: {
      type: Boolean,
      default: false
    },

    headlineSize: {
      type: String,
      default: 'sm'
    },

    headline: {
      type: String,
      default: null
    },

    text: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    },

    button: {
      type: String,
      default: null
    },

    list: {
      type: String,
      required: false,
      default: ''
    },

    variant: {
      type: String,
      required: false,
      default: null
    }
  },

  data () {
    return {
      subscribed: false,
      loading: false,
      email: ''
    }
  },

  computed: {
    ...mapGetters({
      settings: 'storyblok/settings',
      loggedIn: 'member/loggedIn'
    }),
  },

  mounted () {
    if (this.focusOnMount) {
      this.$refs.newsletterInput.focus()
    }
  },

  methods: {
    getHeadline () {
      return this.headline || this.settings?.newsletterHeadline
    },

    getText () {
      return this.text || this.settings?.newsletterText
    },

    getPlaceholder () {
      return this.placeholder || this.settings?.newsletterPlaceholder
    },

    getButton () {
      return this.button || this.settings?.newsletterButton
    },

    submit () {
      const listId = this.list || this.settings?.newsletterList

      if (!listId) {
        return
      }

      const validateEmail = this.validateEmail(this.email)
      if (!validateEmail) {
        this.$notify({
          title: this.$t('Notification_Newsletter_Error_Title'),
          text: this.$t('Notification_Newsletter_Error_Text'),
          type: 'error'
        })
        return
      }

      this.loading = true

      this.$backendApi.post(`klaviyo/list/${listId}/subscribe`, {
        email: this.email
      }).then(() => {
        this.subscribed = true
        this.$analytics.userProperties({ email_address: this.email })
        this.$analytics.signUp('newsletter')
      }).finally(() => {
        this.email = ''
        this.loading = false
        this.$emit('subscribed')
      }).catch((e) => {
        if (e.response.status === 409) {
          this.$notify({
            title: this.$t('Notification_Newsletter_Already_Subscribed_Title'),
            text: this.$t('Notification_Newsletter_Already_Subscribed_Text'),
            type: 'error'
          })
          return
        }
        this.$sentryMiddleware.captureError('Newsletter Subscribe', new Error('Backend Bad Response', { cause: e }))
        this.$notify({
          title: this.$t('Notification_Newsletter_Error_Title'),
          text: this.$t('Notification_Newsletter_Error_Text'),
          type: 'error'
        })
      })
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
